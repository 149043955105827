import { getSettingsAPI } from '@/api/app';
import { removeNullProperties } from '@/utils/remove-null-properties';
import { HomeIcon, IdentificationIcon } from '@heroicons/vue/24/outline';
import { klona } from 'klona/json';
import localForage from 'localforage';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref, unref } from 'vue';
import { useRoute } from 'vue-router';

const cache = localForage.createInstance({
  name: 'YaxoApp',
});

export const useAppStore = defineStore('app', () => {
  const route = useRoute();
  const globalSettings = ref(null);

  const isNarrowSidebar = ref(false);
  const isSideBarOpened = ref(false);
  const apiBaseUrl = ref('');
  const loading = ref(true);

  const appName = computed(() => {
    return globalSettings.value?.app?.appName ?? 'Yaxo';
  });
  const organizationName = computed(() => {
    return `Groupe Yaxo • Nom de l'entité`;
  });

  const thirdParty = computed(() => {
    return globalSettings.value?.thirdParty ?? {};
  });
  const contact = computed(() => {
    return globalSettings.value?.contact ?? {};
  });
  const bankAccount = computed(() => {
    return globalSettings.value?.bankAccount ?? {};
  });

  const countries = computed(() => {
    return globalSettings.value?.app?.countries ?? [];
  });

  const thirdPartyTabs = computed(() => {
    return thirdParty.value.thirdPartyTabs ?? [];
  });

  const thirdPartyModelTypes = computed(() => {
    return thirdParty.value.thirdPartyModelTypes ?? [];
  });

  const thirdPartyModelTypeSlugs = computed(() => {
    let items = thirdParty.value.thirdPartyModelTypes ?? [];
    return items.map(item => item.slug);
  });

  const thirdPartyTypes = computed(() => {
    return thirdParty.value.thirdPartyTypes ?? [];
  });

  const thirdPartyStatuses = computed(() => {
    let thirdPartyStatuses = thirdParty.value.thirdPartyStatuses ?? {};
    return removeNullProperties(thirdPartyStatuses);
  });

  const thirdPartyAlertTypes = computed(() => {
    return thirdParty.value.thirdPartyAlertTypes?.default?.items || [];
  });

  const thirdPartyEndpoints = computed(() => {
    return thirdParty.value.thirdPartyEndpoints ?? [];
  });

  const legalProfiles = computed(() => {
    let thirdPartyLegalProfiles = thirdParty.value.thirdPartyLegalProfiles ?? [];
    return removeNullProperties(thirdPartyLegalProfiles);
    /* const allItems = Object.values(legalProfiles).flatMap(type => type.items);
    const uniqueItems = Array.from(new Map(allItems.map(item => [item.id, item])).values());
    return uniqueItems;*/
  });

  const contactPriorities = computed(() => {
    return contact.value?.contactPriorities ?? [];
  });

  const identificationModeTypes = computed(() => {
    let thirdPartyIdentificationModeTypes = thirdParty.value.thirdPartyTypes ?? [];
    return thirdPartyIdentificationModeTypes;
  });

  const isThirdPartyDuplicateAllowed = computed(() => {
    let thirdPartyIsDuplicatedAllowed = thirdParty.value.thirdPartyIsDuplicatedAllowed ?? false;
    return thirdPartyIsDuplicatedAllowed;
  });

  const identificationModeEntryTypes = computed(() => {
    let modeEntryTypes = thirdParty.value?.thirdPartyIdentificationModeEntryTypes ?? [];
    return modeEntryTypes.map(item => {
      item.scope = item.slug;
      return item;
    });
  });

  const thirdPartyDisabledFields = computed(() => {
    return thirdParty.value.thirdPartyDisabledFields ?? [];
  });
  const thirdPartyComplianceProfiles = computed(() => {
    return thirdParty.value.thirdPartycomplianceProfiles?.default?.items || [];
  });

  const jobTitles = computed(() => {
    return contact.value?.jobTitles ?? [];
  });

  const bankAccountIdentifierTypes = computed(() => {
    return bankAccount.value?.bankAccountIdentifierTypes ?? [];
  });

  const listTypeOfColumns = computed(() => {
    return bankAccount.value?.view?.listing?.columns ?? [];
  });

  const ibanListSchema = computed(() => {
    return listTypeOfColumns.value?.iban ?? {};
  });

  const bbanListSchema = computed(() => {
    return listTypeOfColumns.value?.bban ?? {};
  });

  const bankAccountStatuses = computed(() => {
    return bankAccount.value?.bankAccountStatuses ?? [];
  });

  const bankAccountValidStatus = computed(() => {
    return bankAccountStatuses.value.find(item => {
      return 'valide' === item?.slug;
    });
  });

  const bankAccountInvalidStatus = computed(() => {
    return bankAccountStatuses.value.find(item => {
      return 'invalid' === item?.slug;
    });
  });
  const isStepBankAccountRequired = computed(() => {
    return true === !!bankAccount.value?.isStepRequired;
  });

  const getGlobalSettings = async () => {
    const cacheKey = 'global-settings';
    const ttl = 3600 * 1000; // 1 hour
    const cachedData = await cache.getItem(cacheKey);
    const now = Date.now();
    if (cachedData && now < cachedData.expiration) {
      globalSettings.value = cachedData.data;
      // return;
    }

    const newData = await getSettingsAPI();
    if (!newData) {
      throw new Error('Failed to load global Settings');
    }

    await cache.setItem(cacheKey, {
      data: newData,
      expiration: now + ttl,
    });

    globalSettings.value = newData;
    // isNarrowSidebar.value = globalSettings.value?.app?.isNarrowSidebar || false;

    return newData;
  };

  const getGroups = (settingOption, slugs) => {
    settingOption = unref(settingOption);
    settingOption = klona(settingOption);

    slugs = unref(slugs);
    slugs = klona(slugs);

    const groups = [];
    if (settingOption?.['default'] ?? false) {
      const defaultGroup = {};
      defaultGroup.label = settingOption['default']?.label;
      defaultGroup.slug = 'shared';
      defaultGroup.code = settingOption['default']?.code ?? uuidv4();
      defaultGroup.items = settingOption['default']?.items ?? [];
      groups.push(defaultGroup);
    }
    if (!slugs.length) {
      return groups;
    }
    slugs.forEach(slug => {
      if ('default' !== slug) {
        if (settingOption?.[slug] ?? false) {
          const group = {};
          group.label = settingOption[slug]?.label ?? '';
          group.slug = slug;
          group.code = settingOption[slug]?.code ?? uuidv4();
          group.items = settingOption[slug]?.items ?? [];
          groups.push(group);
        }
      }
    });

    return groups;
  };

  /*
  const clearCache = async cacheKey => {
    await cache.removeItem(cacheKey);
  };
  */

  const navigation = computed(() => [
    {
      id: '8b204268-ed49-481d-b3c2-10222422b694',
      shortTitle: `Tableau de bord`,
      title: `Tableau de bord`,
      href: '/',
      icon: HomeIcon,
      current: (() => route.name == 'Dashboard')(),
    },
    {
      id: 'e9983c74-1a75-4920-a668-68f818ee4ea8',
      shortTitle: 'Tiers',
      title: 'Gestion des tiers',
      href: '/third-parties',
      icon: IdentificationIcon,
      current: (() => route.name == 'ThirdParties')(),
    },
    /* {
      id: '3e45ce1f-d613-41a8-8507-2c29e0b05f49',
      shortTitle: 'Contacts',
      title: 'Gestion des contacts',
      href: '/contacts',
      icon: UserCircleIcon,
      current: (() => route.name == 'Contacts')(),
    },
    {
      id: '8bcc2b12-7ec8-45db-ac13-1f10a6bcd265',
      shortTitle: 'Comptes bancaires',
      title: 'Gestion des comptes bancaires',
      href: '/bank-accounts',
      icon: CurrencyEuroIcon,
      current: (() => route.name == 'BankAccounts')(),
    },*/
  ]);

  const confirmationMessages = ref({
    supplier: {
      patch: {
        'status-valid': '',
      },
    },
  });

  return {
    loading,
    appName,
    organizationName,
    isSideBarOpened,
    isNarrowSidebar,
    apiBaseUrl,
    navigation,
    countries,
    thirdPartyTypes,
    thirdPartyTabs,
    thirdPartyModelTypes,
    thirdPartyModelTypeSlugs,
    thirdPartyStatuses,
    thirdPartyAlertTypes,
    thirdPartyEndpoints,
    isThirdPartyDuplicateAllowed,
    legalProfiles,
    contactPriorities,
    confirmationMessages,
    identificationModeTypes,
    identificationModeEntryTypes,
    thirdPartyDisabledFields,
    thirdPartyComplianceProfiles,
    jobTitles,
    bankAccountIdentifierTypes,
    ibanListSchema,
    bbanListSchema,
    bankAccountStatuses,
    bankAccountValidStatus,
    bankAccountInvalidStatus,
    isStepBankAccountRequired,
    globalSettings,
    getGroups,
    getGlobalSettings,
  };
});
