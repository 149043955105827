//import { useAuthStore } from '@/stores/auth';
export default async function ({ to, next, store }) {
  const isAuthenticated = !!store.isAuthenticated; // Replace with your actual auth logic

  if (isAuthenticated && to.name === 'Login') {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
}
