import authMiddleware from '@/middlewares/auth';
import LogMiddleware from '@/middlewares/log';
import middlewarePipeline from '@/middlewares/middleware-pipeline';
import redirectIfAuthenticated from '@/middlewares/redirect-If-authenticated';
import isSuperAdmin from '@/middlewares/superadmin';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import SignUp from '@/views/SignUp.vue';
import { boolean } from 'boolean';
import { createRouter, createWebHistory } from 'vue-router';

const Login = () => import('@/views/login/Login.vue');
const Logout = () => import('@/views/login/Logout.vue');
const Dashboard = () => import('@/apps/dashboard/Dashboard.vue');
const Contacts = () => import('@/apps/contacts/listing/Contacts.vue');
const ThirdParties = () => import('@/apps/third-parties/ThirdParties.vue');
const ThirdParty = () => import('@/apps/third-party/ThirdParty.vue');
const BankAccounts = () => import('@/apps/bank-accounts/listing/BankAccounts.vue');
const Account = () => import('@/views/account/Account.vue');
const Profile = () => import('@/views/account/Profile.vue');
const Settings = () => import('@/apps/settings/Settings.vue');
const Error = () => import('@/components/AppError.vue');
const AuthCallback = () => import('@/apps/auth/AuthCallback.vue');
const AppUnauthorized = () => import('@/components/AppUnauthorized.vue');
const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: [LogMiddleware, redirectIfAuthenticated],
      layout: 'AppLayoutBasic',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutBasic',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      middleware: [LogMiddleware, authMiddleware],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      middleware: [LogMiddleware, authMiddleware],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/third-parties',
    children: [
      {
        path: '',
        name: 'ThirdParties',
        component: ThirdParties,
        meta: {
          middleware: [LogMiddleware, authMiddleware],
          layout: 'AppLayoutDefault',
        },
      },
      {
        path: ':id',
        component: ThirdParty,
        meta: {
          middleware: [LogMiddleware, authMiddleware],
          layout: 'AppLayoutDefault',
        },
      },
    ],
  },
  {
    path: '/bank-accounts',
    name: 'BankAccounts',
    component: BankAccounts,
    meta: {
      middleware: [LogMiddleware, authMiddleware],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      middleware: [LogMiddleware, authMiddleware, isSuperAdmin],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Profile,
    meta: {
      middleware: [LogMiddleware, authMiddleware],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutBasic',
    },
  },
  {
    path: '/auth/kinde-callback',
    name: 'AuthCallback',
    component: AuthCallback,
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutBasic',
    },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: AppUnauthorized,
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutBasic',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    props: route => ({
      errorReferral: route.query.errorReferral,
      errorMessage: route.query.errorMessage,
    }),
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      middleware: [LogMiddleware],
      layout: 'AppLayoutBasic',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, from, next) => {
  const modalStore = useModalStore();
  const store = useAuthStore();
  modalStore.closeDialog();
  const isDev = boolean(process.env.NODE_ENV === 'development');
  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
    //   store  | You can also pass store as an argument
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});
export default router;
