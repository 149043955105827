import { me } from '@/api/users';
import { convertPipeToArray } from '@/utils/convert-pipe-to-array';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);
  const userProfile = ref(null);
  const accessToken = ref(null);
  const roles = ref([]);
  const permissions = ref([]);
  const redirectTo = ref(null);

  const logout = () => {
    isAuthenticated.value = false;
    userProfile.value = null;
    accessToken.value = null;
    redirectTo.value = null;
  };

  const getMe = async () => {
    try {
      let profile = await me();
      if (true === !!(profile?.id ?? false)) {
        let fullName = `${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`;
        profile.fullName = fullName.trim();
        // profile.roles = profile.roles.filter(role => 'superadmin' !== role.slug);
        userProfile.value = profile;
        roles.value = profile?.roles ?? [];
        permissions.value = profile?.permissions ?? [];
        // permissions.value.push('bankaccount:validate');
        // permissions.value.push('bankaccount:create');
        // permissions.value.push('bankaccount:verify');
        /*permissions.value = roles.value
          .map(role => role.permissions.map(permission => permission.slug))
          .flat();*/
      } else {
        throw new Error('Not authorized');
      }

      return profile;
    } catch (error) {
      console.log(error);
      throw new Error('Failed to fetch me');
    }
  };

  const roleSlugs = computed(() => {
    return roles.value.map(item => item?.slug).filter(e => e);
  });

  const isSuperAdmin = computed(() => {
    if (roleSlugs.value.includes('superadmin')) {
      return true;
    }
    return false;
  });

  const hasAnyRole = rolesList => {
    if (typeof rolesList === 'string') {
      rolesList = convertPipeToArray(rolesList);
    }
    if (!Array.isArray(rolesList)) {
      throw new Error('roles must be an array');
    }
    if (roleSlugs.value.includes('superadmin')) {
      return true;
    }

    // Check if any of the roles match the user's roles
    return rolesList.some(role => roleSlugs.value.includes(role));
  };

  const hasDirectPermission = permissionsList => {
    if (typeof permissionsList === 'string') {
      permissionsList = convertPipeToArray(permissionsList);
    }

    if (!Array.isArray(permissionsList)) {
      throw new Error('permissions must be an array');
    }

    // Check if any of the roles match the user's roles
    return permissionsList.some(permission => permissions.value.includes(permission));
  };
  const hasAllDirectPermissions = permissionsList => {
    if (typeof permissionsList === 'string') {
      permissionsList = convertPipeToArray(permissionsList);
    }

    if (!Array.isArray(permissionsList)) {
      throw new Error('permissions must be an array');
    }

    // Check if any of the roles match the user's roles
    return permissionsList.every(permission => permissions.value.includes(permission));
  };

  const can = permissionsList => {
    if (true === !!isSuperAdmin.value) {
      return true;
    }
    return hasDirectPermission(permissionsList);
  };
  const canPerformAll = permissionsList => {
    if (true === !!isSuperAdmin.value) {
      return true;
    }
    return hasDirectPermission(permissionsList);
  };

  return {
    isAuthenticated,
    roles,
    permissions, // Direct permissions
    isSuperAdmin,
    userProfile,
    accessToken,
    redirectTo,
    roleSlugs,
    logout,
    getMe,
    hasAnyRole,
    can,
    canPerformAll,
  };
});
