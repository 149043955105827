export function convertPipeToArray(pipeString) {
  pipeString = pipeString.trim();

  if (pipeString === '') {
    return [''];
  }

  if (pipeString.length <= 2) {
    return pipeString.split('|').filter(str => str !== '');
  }

  const quoteCharacter = pipeString.charAt(0);
  const endCharacter = pipeString.charAt(pipeString.length - 1);

  if (quoteCharacter !== endCharacter) {
    return pipeString.split('|').filter(str => str !== '');
  }

  if (!["'", '"'].includes(quoteCharacter)) {
    return pipeString.split('|').filter(str => str !== '');
  }

  return pipeString
    .slice(1, -1)
    .split('|')
    .filter(str => str !== '');
}
