import axiosInstance from '@/utils/axios-instance';
// import { sleep } from '@/utils/sleep';

export async function fetchRoles(params = {}, payload = {}) {
  const { page = 1, pageSize = 100 } = params;
  const data = {
    ...payload,
  };
  return axiosInstance({
    url: `roles/?page=${page}&pageSize=${pageSize}`,
    method: 'get',
    data,
  });
}

export async function fetchUsers(params = {}, payload = {}) {
  const { page = 1, pageSize = 10, endpoint = '' } = params;
  const data = {
    sortField: 'firstName',
    sortOrder: 1,
    ...payload,
  };
  return axiosInstance({
    url: `users`,
    method: 'get',
    data,
  });
}

export async function fetchUserById(id) {
  return axiosInstance({
    url: `users/${id}`,
    method: 'get',
  });
}

export async function patchUserApi(id, payload) {
  await commitPatches(id, payload);
}

export async function storeUserApi(payload = null) {
  if (!payload) {
    throw new Error('Invalid payload');
  }
  const data = {
    ...payload,
  };
  return axiosInstance({
    url: `users`,
    method: 'post',
    data,
  });
}

export async function commitPatches(id, payload) {
  return axiosInstance({
    url: `users/${id}`,
    method: 'patch',
    data: payload,
  });
}

export async function deleteUserApi(id) {
  return axiosInstance({
    url: `users/${id}`,
    method: 'delete',
  });
}

export async function me() {
  return axiosInstance({
    url: `users/me`,
    method: 'post',
  });
}
